import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { ProductService } from './product.service';
import { Product } from "../classes/product";
import { LoginService } from 'src/app/mylogin/loginc/login.service';
@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  products: Product[] = [];
  constructor(
    public router: Router,
    public productService: ProductService,
    public Loginservice: LoginService
  ) {

    this.productService.cartItems.subscribe((response) => {


      this.products = response;
      console.log("🚀 > CartComponentsssssssssssssssssssssssssssssssssssssssssssssssssssssss > ngonitse > response", this.products);



    });
  }



  logout() {

    if (this.products.length > 0) {
      if (confirm("Would you like to add the items in your cart to your wishlist for a future order?") == false) {
        const setting = localStorage.getItem('setting');
        localStorage.clear();
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('user');
        localStorage.removeItem('products')
        localStorage.removeItem('cartItems')
        localStorage.removeItem('customercredit')
        localStorage.removeItem('customerbalance')
        localStorage.removeItem('customername')
        localStorage.removeItem('country')
        localStorage.removeItem('promotiondata')

        if (setting != null) {
          localStorage.setItem('setting', setting);
        }

        // this.router.navigate(['/company']);
        //.location.reload();
        this.router.navigate(['/company']).then(() => {
          window.location.reload();
        });


      }

      else {


            console.log(this.products);



            const itemList = this.products.map(product => ({
              itemName: product.title,
              itemNumber: product.id,
              quantity: product.quantity
            }));
            
            console.log(itemList);

            let data = {
              customer_Id: localStorage['customer_Id'],
              customerNumber: localStorage['customerNumber'],
              itemList : itemList
            }

            console.log(data);
            this.Loginservice.addtowishlistonlogout(data).subscribe((res:any)=>{
              
              console.log("After logut Wishlist");
              console.log(res);

              const setting = localStorage.getItem('setting');
      localStorage.clear();
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('user');
      localStorage.removeItem('products')
      localStorage.removeItem('cartItems')
      localStorage.removeItem('customercredit')
      localStorage.removeItem('customerbalance')
      localStorage.removeItem('customername')
      localStorage.removeItem('country')
      localStorage.removeItem('promotiondata')

      if (setting != null) {
        localStorage.setItem('setting', setting);
      }

      // this.router.navigate(['/company']);
      //.location.reload();
      this.router.navigate(['/company']).then(() => {
        window.location.reload();
      });

        
              
            })

      }

    }
    else {

      const setting = localStorage.getItem('setting');
      localStorage.clear();
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('user');
      localStorage.removeItem('products')
      localStorage.removeItem('cartItems')
      localStorage.removeItem('customercredit')
      localStorage.removeItem('customerbalance')
      localStorage.removeItem('customername')
      localStorage.removeItem('country')
      localStorage.removeItem('promotiondata')

      if (setting != null) {
        localStorage.setItem('setting', setting);
      }

      // this.router.navigate(['/company']);
      //.location.reload();
      this.router.navigate(['/company']).then(() => {
        window.location.reload();
      });

    }

  }

}
