

<div *ngIf="!loader">

  


  <table class="table cart-table table-bordered table-responsive-xs">
    
    
        <tr>
            <td style="width: 100px;">
              <div class="lable-block">
               
                <span class="lable4" 
                      *ngIf="product.promoProduct || product.discount > 0" 
                      [title]="product.promotionDescription">
                  Promo
                </span>
              </div>
                    <img 

        
        class="img-fluid lazy-loading" 
        [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
        [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" 
        alt="{{ product.images[0].alt }}"
        style="height: 60px;"
        />
                
            </td>
            <td style=" width: 170px; text-align:  left  !important; font-size: 14px;">
              <h6>  
              Item Code: {{ product?.id | titlecase }}
            </h6>
            </td>
            <td style=" width: 230px; text-align:  left  !important; font-size: 14px;">
              <h6 [title]="product.title">
              {{ product?.title | slice:0:25 }}{{ product?.title?.length > 20 ? '...' : '' }}
            </h6>
            </td> 
            <td style=" width: 250px; text-align:  left  !important; font-size: 14px;">

              <h6>
            MOQ {{ product?.moq }} cases increased by {{ product?.mo }} cases
          </h6>  
          </td>
            <td style=" width: 200px; text-align: left !important;">
                
                
                  <h6 [style.color]="product?.discount > 0 ? 'green' : 'inherit'"> 
        
                    Per Case: {{
                      product?.price * currency?.price
                        | discount : product
                        | currency : currency?.currency : "symbol"
                    }}
                    <del *ngIf="product?.discount > 0"
                      ><span class="money">
                        {{
                          product?.price * currency?.price
                            | currency : currency?.currency : "symbol"
                        }}</span
                      ></del
                    >
                  </h6>
                 
            </td>
            <td style=" width: 200px; text-align: left !important;">
                
                
             
              <h6 [style.color]="product?.discount > 0 ? 'green' : 'inherit'"> 
    
                Per Retail Pack: 
                {{
                  product?.pricepercase * currency?.pricepercase 
                     | caseDiscount : product
                     | currency : currency?.currency : "symbol"
                 }} 
                 <del *ngIf="product?.discount > 0"
                   ><span class="money">
                     {{
                       product?.pricepercase * currency?.price
                         | currency : currency?.currency : "symbol"
                     }}</span
                   ></del
                 >
              </h6>
        </td>
            <td style="text-align: center !important;">
                <div class="qty-box">
                    <div class="input-group">
                        <span class="input-group-prepend">
                             <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrementQuantity(product,product.mo)">
                                <i class="ti-angle-left"></i>
                            </button> 
                        </span>
                        <input type="number" name="quantity" style="width:80px" class="form-control input-number" (focusout)="setqty(product,quantity)" name="quantity" [(ngModel)]="quantity">
                        <span class="input-group-prepend">
                             <button type="button" class="btn quantity-right-plus"  data-type="plus" (click)="incrementQuantity(product,product.mo)">
                                <i class="ti-angle-right"></i>
                            </button> 
                        </span>
                    </div>
                </div>
            </td>
            <!-- <td>
                <a href="javascript:void(0)" (click)="removeItem(product)" class="icon">
                <i class="ti-close"></i>
            </a>
            </td> -->
            <td>
                <h2 class="td-color" >
                    {{ ((product.price | discount:product) * product.quantity) * product?.price | currency:currency.currency:'symbol' }}
                </h2>
            </td>
        </tr>
    
</table>
</div>