import { Component, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalobjectsService } from "../../globalobjects.service";
import { LoginService } from "../loginc/login.service";
import { ToastrService } from "ngx-toastr";
import { LogoutService} from "src/app/shared/services/logout.service";
import { response } from "express";

@Component({
  selector: "app-select-customer",
  templateUrl: "./select-customer.component.html",
  styleUrls: ["./select-customer.component.scss"],
})
export class SelectCustomerComponent {
  constructor(
    private toastrService: ToastrService,
    private route: Router,
    public Loginservice: LoginService,
    public Globalobjects: GlobalobjectsService,
    public router: Router,
    public logoutservices:LogoutService
  ) {
    this.countryName = localStorage.getItem("country");
  }
  countryName: any;
  msg: string;
  loginError: string;
  companylist: any;
  logoPath: string;
  customersList: any;
  selectedCustomer: any;
  PreLoader: boolean = false;
  cname:any ;

  ngOnInit(): void {
    if (localStorage.getItem("isSalesRep") !== "true") {
      this.router.navigate(["/company"]);
    }
    const settingString = localStorage.getItem("setting") as string;
    if (settingString != "undefined" && settingString != null) {
      let setting = JSON.parse(localStorage.getItem("setting") as string);
      this.logoPath = this.Globalobjects.BUSINESSINFOIMAGEURL + setting.logo;
    } else {
      this.logoPath = "assets/images/placeholder-logo-2.png";
    }
    this.customersList = JSON.parse(localStorage.getItem("SalesRepCustomersList") as string);
    this.cname = this.capitalizeName(localStorage.getItem('cname'));

  }
  
  capitalizeName(name: string): string {
    return name?.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  }
  
  isDropdownOpen = false;
  IsSalesRep : boolean = false;
  toggleDropdown(event: Event) {
    this.isDropdownOpen = !this.isDropdownOpen;
    event.stopPropagation();
  }

  
  @HostListener("document:click", ["$event"])
  closeDropdown(event: Event) {
    this.isDropdownOpen = false;
  }

  logout() {
    // const setting = localStorage.getItem("setting");
    // localStorage.clear();
    // localStorage.removeItem("isLoggedIn");
    // localStorage.removeItem("user");
    // localStorage.removeItem("products");
    // localStorage.removeItem("cartItems");
    // localStorage.removeItem("customercredit");
    // localStorage.removeItem("customerbalance");
    // localStorage.removeItem("customername");
    // localStorage.removeItem("country");
    // if (setting != null) {
    //   localStorage.setItem("setting", setting);
    // }

    // // this.router.navigate(['/company']);
    // //.location.reload();
    // this.router.navigate(["/company"]).then(() => {
    //   window.location.reload();
    // });
    this.logoutservices.logout();
  }

  selectCountry(customerId: any) {
    this.selectedCustomer = this.customersList.find( (customer: any) => customer.id == customerId);

    console.log("🚀 > SelectCustomerComponent > selectCountry >  this.selectedCustomer:",  this.selectedCustomer)
  }

  onSubmit() {
    // Access the values of userName and userPass here

    console.log("Selected Customer: " + this.selectedCustomer);

    localStorage.setItem("customerNumber", this.selectedCustomer.number);
    if (this.selectedCustomer) {
      const itemdata = {
        countryList_Id: this.countryName,
        customerNumber: this.selectedCustomer.number,
      };
      this.PreLoader = true;
      this.fetchItems(itemdata)
    } else {
      alert("Please Select Customer");
    }
  }



  fetchItems(customerData: any) {
    

    this.Loginservice.GetItmes(customerData).subscribe((itemsResponse: any) => {
      localStorage["loginresponse"] = JSON.stringify(itemsResponse);
      console.log("Items API Response:", itemsResponse);
      localStorage.setItem("customercredit", itemsResponse.customerInfo.creditLimit);
      localStorage.setItem("customerbalance", itemsResponse.customerInfo.balanceDue);
      localStorage.setItem("pricegroup", itemsResponse.customerInfo.customerPriceGroup);
      localStorage.setItem("shippingcode", itemsResponse.customerInfo.shipToCode);
      localStorage.setItem("selectedCustomerName", itemsResponse.customerInfo.customerName);
      // Fetch promotions after fetching items
      this.getPromotionDetails(itemsResponse);
    });
  }

  getPromotionDetails(itemsResponse: any) {
    const data = {
      countryList_Id: localStorage.getItem("country"),
      customerNumber: this.selectedCustomer.number,
      customerPriceGroup: localStorage.getItem("pricegroup"),
    };
    this.Loginservice.getPromotionDetails(data).subscribe((promoResponse: any) => {
      console.log("Promotion Details API Response:", promoResponse);
      localStorage.setItem("promotiondata", JSON.stringify(promoResponse.data));
      // Map data and finalize processing with promo check
      this.mapAndStoreData(itemsResponse, promoResponse);

      
    });
  }

  mapAndStoreData(itemsResponse: any, promoResponse: any) {
    const apiData = itemsResponse.itemInfo;
    const promotionData = promoResponse.data;

    // Iterate through the items
    for (let i = 0; i < apiData.length; i++) {
      // Add Variants array to each element
      apiData[i].Variants = [
        {
          variant_id: 101,
          id: 1,
          sku: "sku1",
          size: "s",
          color: "yellow",
          image_id: 111,
        },
        // Add other variants as needed
      ];

      // Add Images array to each element
      apiData[i].Images = [
        {
          image_id: 111,
          id: 1,
          alt: "yellow",
          src: "assets/images/product/fashion/39.jpg",
          variant_id: [101, 104],
        },
        {
          image_id: 112,
          id: 1,
          alt: "white",
          src: "assets/images/product/fashion/6.jpg",
          variant_id: [102, 105],
        },
        // Add other images as needed
      ];
    }

   // Check promotions and add promoProduct flag
    promotionData.forEach((promotion: any) => {
      if (promotion.isActive) {
        const currentDate = new Date();
        const startDate = new Date(promotion.date_Start);
        const endDate = new Date(promotion.date_End);

        if (currentDate >= startDate && currentDate <= endDate) {
          if (promotion.isApply_MinQty) {
          promotion.promotion_Items.forEach((promoItem: any) => {
            if (promoItem.isInput) {
            apiData.forEach((item: any) => {
              if (promoItem.itemNumber === item.itemNumber && promoItem.isInput) {
                item.promoProduct = true;
                item.promotionDescription = promotion.promotionDescription;
              }
            });
          }
          });
        }
      }
      }
    });

    const mappedData = apiData.map((item: any) => {
      return {
        id: item.itemNumber,
        title: item.itemName,
        description: "Our Products",
        type: item.itemCategoryCode,
        moq: item.mininumOrderQuantity,
        mo: item.multipleOff,
        brand: "",
        collection: ["new products"],
        category: "",
        price: item.unitPrice,
        sale: item.isDiscount,
        discount: item.discountPercent,
        stock: 10000,
        commonItemNo: item.commonItemNo ? item.commonItemNo : item.itemNumber,
        new: false,
        sortingno: item.portalSortingNo,
        pricepercase: (item.unitPrice && item.noOfRetailsInACase) ? (item.unitPrice / item.noOfRetailsInACase) : 0,
        isPriceGroup: item.isPriceGroup,
        promoProduct: item.promoProduct || false, // Default to false if not set
        promotionDescription: item.promotionDescription || "", // Default to empty string if not set
        tags: [],
        variants: item.Variants.map((variant: any) => {
          return {
            variant_id: variant.variant_id,
            id: variant.id,
            sku: variant.sku,
            size: variant.size,
            color: variant.color,
            image_id: variant.image_id,
          };
        }),
        images: item.Images.map((image: any) => {
          return {
            image_id: image.image_id,
            id: image.id,
            alt: image.alt,
            src:
              this.countryName + "_" + item.itemNumber + ".png" &&
              this.countryName + "_" + item.itemNumber + ".png".trim() !== ""
                ? this.countryName + "_" + item.itemNumber + ".png"
                : "placeholder.jpg",
            variant_id: image.variant_id,
          };
        }),
      };
    });

    this.Globalobjects.productdata = mappedData;
    localStorage["rproducts"] = JSON.stringify(mappedData);

    // console.log("Mapped Data:", mappedData);

    // console.log(localStorage.getItem("isLoggedIn"));

    this.PreLoader = false;
      this.route.navigate(["/index"]).then(() => {
        window.location.reload();
      });
  }
}
