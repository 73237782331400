import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'discount'
})
export class DiscountPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const price = args.discount ? args.price - (args.price * args.discount / 100) : args.price
    return price
  }

}

@Pipe({
  name: 'caseDiscount'
})
export class CaseDiscountPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const pricepercase = args.discount ? 
      args.pricepercase - (args.pricepercase * args.discount / 100) : 
      args.pricepercase;
    return pricepercase ;
  }
}