import { Component, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalobjectsService } from "src/app/globalobjects.service";
import { LogoutService} from "src/app/shared/services/logout.service";
@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
})
export class DropdownComponent {
  constructor(public router: Router , public GlobalobjectsService:GlobalobjectsService , public logoutservices:LogoutService ) {}
  isDropdownOpen = false;
  IsSalesRep: boolean = false;
  toggleDropdown(event: Event) {
    this.isDropdownOpen = !this.isDropdownOpen;
    event.stopPropagation();
  }
  ngOnInit(): void {
    if (localStorage.getItem("isSalesRep") == "true") {
      this.IsSalesRep = true;
    }
  }

  @HostListener("document:click", ["$event"])
  closeDropdown(event: Event) {
    this.isDropdownOpen = false;
  }

  logout() {
    // const setting = localStorage.getItem("setting");
    // localStorage.clear();
    // localStorage.removeItem("isLoggedIn");
    // localStorage.removeItem("user");
    // localStorage.removeItem("products");
    // localStorage.removeItem("cartItems");
    // localStorage.removeItem("customercredit");
    // localStorage.removeItem("customerbalance");
    // localStorage.removeItem("customername");
    // localStorage.removeItem("country");
    // if (setting != null) {
    //   localStorage.setItem("setting", setting);
    // }

    // // this.router.navigate(['/company']);
    // //.location.reload();
    // this.router.navigate(["/company"]).then(() => {
    //   window.location.reload();
    // });
    this.logoutservices.logout();
  }
}
