import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { GlobalobjectsService } from 'src/app/globalobjects.service';
import { LoginService } from 'src/app/mylogin/loginc/login.service';
import { HeaderOneComponent } from 'src/app/shared/header/header-one/header-one.component';

const state = {
  products: localStorage.getItem('products') || '[]',
  wishlist: JSON.parse(localStorage.getItem('wishlistItems') || '[]'),
  compare: JSON.parse(localStorage.getItem('compareItems') || '[]'),
  cart: JSON.parse(localStorage.getItem('cartItems') || '[]')
};

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc: string
  quantity: number = 0;


  constructor(private productService: ProductService , public Globalobjects:GlobalobjectsService, public Loginservice:LoginService, ) { }

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    this.ImageSrc = this.Globalobjects.ImageURL + this.product.images[0].src;
    // this.quantity = this.product.moq;
  }


  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
    const loginresponse = JSON.parse(localStorage['loginresponse']);
    console.log("🚀 > ProductBoxOneComponent > addToWishlist > loginresponse:", loginresponse)
    let data = {
      "customer_Id": localStorage['customer_Id'],
      "customerNumber": localStorage['customerNumber'],
      "itemName": product.title,
      "itemNumber": product.id,
      "quantity": product.moq,
    }

    console.log("===Data for Single Wishlist===");
    console.log(data);

    this.Loginservice.addtowishlist(data).subscribe((res:any)=>{
      console.log(res);

      
    })
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }


  count: any = 0;
  incrementQuantity(product: any, mo: any) {
    this.count++
    if (this.count > 1 || this.quantity > this.product.moq) {
      this.quantity += mo;
      this.productService.addToCartC(product, mo);
    }
    else {
      this.quantity = this.product.moq;
      this.productService.addToCartC(product, this.quantity);
    }
  }

  decrementQuantity(product: any, mo: any) {
    if (this.quantity > this.product.moq) {
      this.quantity -= mo;
      const qty = -product.mo;
      this.productService.updateCartQuantity(product, qty);
    }
  }
  r: any;
  pqty: any;
  checkcurrentqty(qty: any) {
    this.pqty = qty;
  }

  // setqty(product: any, qty: any) 
  // {
  //   if (qty == 0 && this.pqty < 1) {
  //     this.quantity == 0;
  //    }
  //   else if (qty == 0 && this.pqty > 0) {
  //     this.quantity == 0;
      
  //     //yaha last wala nahi karan remove Id Get Karwa k remove krna hai 

  //     this.productService.removeCartItemById(product.id);

  //   }

  //   else {

  //     if (qty < this.product.moq)
  //      {
  //       this.quantity = this.product.moq;
  //       console.log(product.id +" ya id hai jaha  moq se kam dala hai")
  //       this.productService.addToCartC(product, this.quantity);
  //     }
  //     else 
  //     {
  //       this.r = qty % this.product.mo;

  //       if (this.r != 0) {

  //         for (this.r; this.r >= 0; this.r++) {
  //           this.quantity++
  //           qty++;
  //           console.log(this.r)
  //           if (qty % this.product.mo == 0) {

  //             if(qty > 0 && this.pqty > 0)
  //             {

  //                 console.log(product.id +" ya id hai")
                 

  //                 this.productService.removeCartItemById(product.id);
  //                 this.productService.addToCartC(product, this.quantity);
  //             }
  //             else
  //             {
               
  //               this.productService.addToCartC(product, this.quantity);
  //             }           
               
  //             break;
  //           }
  //         }
  //       }
  //       else {
          
  //         // yaha last wala nahi karan remove Id Get Karwa k remove krna hai
  //         this.productService.removeCartItemById(product.id);
  //         this.quantity = qty;
  //         this.productService.addToCartC(product, this.quantity);
  //       }
  //     }

  //   }
  // }

  setqty(product: any, qty: any) {
    // Ensure qty is a valid number
    qty = parseFloat(qty);
  
    if (qty == 0 && this.pqty < 1) {
      this.quantity = 0;
    } 
    else if (qty == 0 && this.pqty > 0) {
      this.quantity = 0;
      this.productService.removeCartItemById(product.id);
    } 
    else {
      if (qty < this.product.moq) {
        this.quantity = this.product.moq;
        console.log(product.id + " ya id hai jaha moq se kam dala hai");
        this.productService.addToCartC(product, this.quantity);
      } 
      else {
        // Calculate remainder of qty divided by product.mo
        this.r = qty % this.product.mo;
  
        if (this.r !== 0) {
          // Adjust qty to the nearest upper multiple of product.mo
          this.quantity = Math.ceil(qty / this.product.mo) * this.product.mo;
          console.log(product.id + " ya id hai, rounded up to the next multiple of mo");
          
          // Remove previous cart item and add the new quantity
          this.productService.removeCartItemById(product.id);
          this.productService.addToCartC(product, this.quantity);
        } 
        else {
          this.quantity = qty;
          this.productService.addToCartC(product, this.quantity);
        }
      }
    }
  }
  
  
  bntcart(product: any) {
    this.productService.addToCartC(product, this.quantity);
  }


  ImageSrcn: string | undefined;
  imageError: boolean = false;

  // Example logic to get ImageSrc
  // You need to replace this with your actual logic to fetch the image source
  loadImageSrc() {
    // Replace the next line with your logic to get the image source
    // For example, this.ImageSrc = someService.getImageSrc();
    // Set this.imageError to false when the image is successfully loaded
    this.ImageSrcn = 'assets/images/product/placeholder.jpg';
    this.imageError = false;
  }

  handleImageError() {
    this.imageError = true;
  }
}
