
<div class="fixed-top">

  <div class="container-fluid pt-2 pb-2 marquee-header" style="background-color: #339533;" >
    <div class="marquee-container ">
      <div class="marquee-content">
        <span
          class="text text-danger"
          style="font-size: medium; font-weight: bold; margin-right: 300px; color:white !important;"
          *ngFor="let remark of promotionDescription"
        >
          {{ remark }}
        </span>
      </div>
    </div>
  </div>
  
  <header class="sticky" [ngClass]="class">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="header-contact">
              <ul>
                <li>
                  <div class="menu-left">
                    <!-- <app-left-menu></app-left-menu> -->
                    <div class="brand-logo">
                      <a routerLink="/mian" id="headerlogo">
                        <img
                          [src]="logoPath"
                          class="img-fluid"
                          alt="logo"
                          width="150"
                        />
                      </a>
                    </div>
                  </div>
                </li>
  
                <li style="color: white ">
                  Credit Limit:
                    <span style="color: white ">{{ credit | currency }} </span>
                </li>
                <a [routerLink]="['/pages/orderHistory']" [queryParams]="{ tab: 3 }">
                  <li style="color: white ">
                    Balance Outstanding:
                      <span style="color: white"
                        >{{ balance | currency }}
                      </span>
                  </li></a
                >
              </ul>
            </div>
          </div>
          <div class="col-lg-6 text-end d-flex align-items-center justify-content-end" >
            <ul class="header-dropdown "  >
              <li class="mobile-home" style="color: white ">
                <a [routerLink]="['/index']" style="color: white ">
                  <i class="fa fa-home" aria-hidden="true" style="color: white "></i>Home</a>
              </li>
  
              <li class="onhover-dropdown mobile-account" style="color: white ">
                <i class="fa fa-list" aria-hidden="true"></i> Menu
                <ul class="onhover-show-div">
                  <li *ngIf="IsSalesRep">
                    <a [routerLink]="['/select-customer']">
                      Select Customer
                    </a>
                  </li>
                  <li >
                    <a [routerLink]="['/shop/placeorder']">
                      Place Order
                    </a>
                  </li>
                  <li>
                    <a [routerLink]="['/pages/orderHistory']">
                      Order/Invoice History
                    </a>
                  </li>
                  <li>
                    <a  [routerLink]="['/pages/orderHistory']" [queryParams]="{ tab: 2 }">Financials</a>
                  </li>
                  <li>
                    <a [routerLink]="['/pages/purchaseTrends']">
                      Purchase Trends
                    </a>
                  </li>
                  <li>
                    <a [routerLink]="['/shop/collection/newlaunch']">
                      New Launches
                    </a>
                  </li>
                  <li>
                    <a [routerLink]="['/pages/promotions']">
                      Promotions
                    </a>
                  </li>
                  <li>
                    <a [routerLink]="['/shop/productspecification']">
                      Product Specifications
                    </a>
                  </li>
                  <li>
                    <a (click)="logout()">Log Out</a>
                  </li>
                </ul>
              </li> 
  
              <li class="mobile-wishlist" style="padding-right: 15px; color: white; ">
                <app-wishlistbutton [WishlistCount]="ProductService.WishlistItemsCount"></app-wishlistbutton>
              </li>
              
              <li class="mobile-home hoverclr">
                <div class="main-menu">
                  <div class="menu-right pull-right">
                    <app-settings> </app-settings>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="main-menu">
            <div class="menu-right pull-right">
              <!-- <div>
                <app-menu></app-menu>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  
</div>